import { Grid, IconButton, Typography, Stack, Popover, Tooltip } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import dayjs, { Dayjs } from 'dayjs';
import * as React from 'react';
import { useState } from 'react';
import 'dayjs/locale/en-gb';
import { getEndOfMonth, getStartOfMonth } from '../../helpers/TimeHelper';

export interface ITimeRangeSelectorProps {
    setStart(start: Dayjs | null): void
    setEnd(end: Dayjs | null): void
    start: Dayjs | null
    end: Dayjs | null
}

const TimeRangeSelector: React.FunctionComponent<ITimeRangeSelectorProps> = (props: ITimeRangeSelectorProps) => {
    const [currentMonth, setCurrentMonth] = useState(dayjs());
    const [isAutoSelecting, setIsAutoSelecting] = useState(true);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const isCurrentMonth = currentMonth.isSame(dayjs(), 'month') && isAutoSelecting;

    const selectMonth = (month: Dayjs) => {
        props.setStart(getStartOfMonth(month));
        props.setEnd(getEndOfMonth(month));
    };

    const handleMonthChange = (direction: 'prev' | 'next') => {
        const newMonth = direction === 'prev' 
            ? currentMonth.subtract(1, 'month')
            : currentMonth.add(1, 'month');
        setCurrentMonth(newMonth);
        setIsAutoSelecting(true);
        selectMonth(newMonth);
    };

    const handleReset = () => {
        const today = dayjs();
        setCurrentMonth(today);
        setIsAutoSelecting(true);
        selectMonth(today);
    };

    const handleStartChange = (date: Dayjs | null) => {
        if (!date) return;
        setIsAutoSelecting(false);
        props.setStart(date.startOf('day'));
        // If end date is before start date, adjust it
        if (props.end && date.isAfter(props.end)) {
            props.setEnd(date.endOf('day'));
        }
        setCurrentMonth(date);
    };

    const handleEndChange = (date: Dayjs | null) => {
        if (!date) return;
        setIsAutoSelecting(false);
        props.setEnd(date.endOf('day'));
        // If start date is after end date, adjust it
        if (props.start && date.isBefore(props.start)) {
            props.setStart(date.startOf('day'));
        }
        handleClose();
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setIsAutoSelecting(false);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getDisplayText = () => {
        if (!isAutoSelecting && props.start && props.end) {
            return 'Custom';
        }
        return currentMonth.format('MMMM YYYY');
    };

    const getDateRangeText = () => {
        if (props.start && props.end) {
            return `${props.start.format('DD/MM/YYYY')} - ${props.end.format('DD/MM/YYYY')}`;
        }
        return '';
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} container justifyContent="center" alignItems="center" spacing={1}>
                        <Grid item>
                            <IconButton onClick={() => handleMonthChange('prev')}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Stack spacing={1} alignItems="center">
                                <Tooltip title="Click to select custom range">
                                    <Typography 
                                        variant="h6" 
                                        sx={{ 
                                            cursor: 'pointer',
                                            width: '160px',
                                            textAlign: 'center'
                                        }}
                                        onClick={handleClick}
                                    >
                                        {getDisplayText()}
                                    </Typography>
                                </Tooltip>
                                {(!isAutoSelecting && props.start && props.end) && (
                                    <Tooltip title="Click to select custom range">
                                        <Typography 
                                            variant="body2" 
                                            color="text.secondary"
                                            sx={{ 
                                                cursor: 'pointer',
                                                width: '160px',
                                                textAlign: 'center'
                                            }}
                                            onClick={handleClick}
                                        >
                                            {getDateRangeText()}
                                        </Typography>
                                    </Tooltip>
                                )}
                            </Stack>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => handleMonthChange('next')}>
                                <ChevronRightIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Reset to current month">
                                <span>
                                    <IconButton 
                                        onClick={handleReset}
                                        disabled={isCurrentMonth}
                                        size="small"
                                        sx={{ 
                                            color: 'error.main',
                                            opacity: 0.7,
                                            '&:hover': {
                                                opacity: 1
                                            },
                                        }}
                                    >
                                        <RestartAltIcon fontSize="small" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Stack 
                        spacing={2} 
                        sx={{ p: 2 }}
                    >
                        <Typography variant="subtitle1" sx={{ fontWeight: 500, textAlign: 'center' }}>
                            Pick Range
                        </Typography>
                        <Stack 
                            direction="row" 
                            spacing={2}
                        >
                            <DatePicker
                                label="Start Date"
                                value={props.start}
                                onChange={handleStartChange}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        fullWidth: true
                                    }
                                }}
                            />
                            <DatePicker
                                label="End Date"
                                value={props.end}
                                onChange={handleEndChange}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        fullWidth: true
                                    }
                                }}
                            />
                        </Stack>
                    </Stack>
                </Popover>
            </Stack>
        </LocalizationProvider>
    );
}

export default TimeRangeSelector;